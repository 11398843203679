import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {SchoolOnMap} from "./school_on_map";
import {POI} from "./models";

interface Props {
    chartType:string
}

interface State {
    sydLat:number
    sydLon:number
    data:POI[]
}


class GoogleMap extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sydLat:-33.88264728369376,
            sydLon:151.20655731609574,
            data:[]
        };
    }

    componentDidMount() {
        fetch('x.json').then((response)=>{
            response.json().then((data)=>{
                let dataArray:any = []
                for (let i = 0; i < data.kml.Document.Folder.length-2; i++){
                    dataArray = dataArray.concat(data.kml.Document.Folder[i].Placemark)
                }
                dataArray = dataArray.map((data:POI)=>{
                    const coo = data.Point.coordinates.split(",");
                    const lon = Number(coo[0]);
                    const lat = Number(coo[1]);
                    const distanceToSyd = this.getDistanceFromLatLonInKm(this.state.sydLat, this.state.sydLon, lat, lon)
                    const logDistanceToSyd = Math.log(distanceToSyd) + 1
                    const sales = this.getRandomInt(logDistanceToSyd*10, logDistanceToSyd*80);
                    const stock = this.getRandomInt(0, 400);
                    data['sales'] = sales
                    data['stocks'] = stock
                    return data
                })
                this.setState({data:dataArray})
            })
        })
    }

    getDistanceFromLatLonInKm(lat1:number,lon1:number,lat2:number,lon2:number) {
        const R = 6371; // Radius of the earth in km
        const dLat = (lat2 - lat1) * (Math.PI/180);  // deg2rad below
        const dLon = (lon2 - lon1) * (Math.PI/180);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI/180)) * Math.cos(lat2 * (Math.PI/180)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
         // Distance in km
        return R * c;
    }

    getRandomInt(min:number, max:number) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    render() {
        const pois = this.state.data.map((poi, index)=>{
            const coo = poi.Point.coordinates.split(",");
            const lon = Number(coo[0]);
            const lat = Number(coo[1]);
            return <SchoolOnMap lat={lat}
                                lng={lon}
                                schoolName={poi.name}
                                numberOfStockLeft={poi.stocks}
                                sevenDaysSales={poi.sales}
                                salesStockSwitch={this.props.chartType}
                                key={index} schoolId={poi.name}/>
        })
        const center = {
            lat: -33.8473567,
            lng: 150.6517969}
        const zoom = 10
        return (
            <div style={{ height: '100vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: "AIzaSyAiK6esuGcx32taWwFdMdQBGlNF0eNQkhs"}}
                    defaultCenter={center}
                    defaultZoom={zoom}
                >
                    {pois}
                </GoogleMapReact>
            </div>
        );
    }
}

export default GoogleMap;

// AIzaSyCpANn_L1vMxNa_xTfrDpgyORYigd1fsCA