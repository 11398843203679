export interface Address{
    Type:string;
    Role:string;
    City:string;
    StateProvince:string;
    PostalCode:string;
    GridLocation:GridLocation;
    Country:string;
    StatisticalAreas:[];
}

export interface GridLocation{
    Latitude:number;
    Longitude:number;
}

export interface Campus{
    ParentSchoolId:string;
    SchoolCampusId:string;
    CampusType:string;
}

export interface School{
    RefId:string;
    LocalId:string;
    StateProvinceId:string;
    CommonwealthId:string;
    ACARAId:string;
    SchoolName:string;
    SchoolDistrict:string;
    SchoolType:string;
    SchoolURL:string;
    OperationalStatus:string;
    Campus:Campus;
    SchoolSector:string;
    IndependentSchool:string;
    SchoolGeographicLocation:string;
    LocalGovernmentArea:string;
    AddressList:Address[];
    SessionType:string;
}

export enum SalesStockSwitch{
    Sales,
    Stocks
}


export interface POI{
    Point:Point;
    description:string;
    name:string;
    sales:number;
    stocks:number;
}

export interface Point{
    coordinates:string
}