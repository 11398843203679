import React, {Component} from 'react';
import {SalesStockSwitch} from "./models";



interface Props {
    lat:number,
    lng:number
    schoolName:string
    sevenDaysSales:number
    numberOfStockLeft:number
    salesStockSwitch:string
    schoolId:string
}

interface State {
    isTooltipActive: boolean
}

class SchoolOnMap extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isTooltipActive: false
        };
    }

    render() {
        if (this.props.salesStockSwitch === SalesStockSwitch.Sales.toString()){
            return <div className="tooltip-self">
                {this.props.sevenDaysSales < 100 && <span className={"school red"} data-tip={this.props.schoolName}>{this.props.sevenDaysSales}</span>}
                {100 <= this.props.sevenDaysSales && this.props.sevenDaysSales < 200  && <span className={"school yellow"} data-tip={this.props.schoolName}>{this.props.sevenDaysSales}</span>}
                {200 <= this.props.sevenDaysSales  && <span className={"school green"} data-tip={this.props.schoolName}>{this.props.sevenDaysSales}</span>}
                <div className="tooltiptext">
                    <table>
                        <tbody>
                            <tr>
                                <th>ID</th>
                                <td>{this.props.schoolId}</td>
                            </tr>
                            <tr>
                                <th>7 days sales</th>
                                <td>{this.props.sevenDaysSales}</td>
                            </tr>
                            <tr>
                                <th>current stock</th>
                                <td>{this.props.numberOfStockLeft}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>;
        }
        if (this.props.salesStockSwitch === SalesStockSwitch.Stocks.toString()){
            return <div className="tooltip-self">
                {this.props.numberOfStockLeft < 100 && <span className={"school red"} data-tip={this.props.schoolName}>{this.props.numberOfStockLeft}</span>}
                {100 <= this.props.numberOfStockLeft && this.props.numberOfStockLeft < 200  && <span className={"school yellow"} data-tip={this.props.schoolName}>{this.props.numberOfStockLeft}</span>}
                {200 <= this.props.numberOfStockLeft  && <span className={"school green"} data-tip={this.props.schoolName}>{this.props.numberOfStockLeft}</span>}
                <div className="tooltiptext">
                    <table>
                        <tbody>
                        <tr>
                            <th>ID</th>
                            <td>{this.props.schoolId}</td>
                        </tr>
                        <tr>
                            <th>7 days sales</th>
                            <td>{this.props.sevenDaysSales}</td>
                        </tr>
                        <tr>
                            <th>current stock</th>
                            <td>{this.props.numberOfStockLeft}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>;
        }
    }
}

export {SchoolOnMap}