import './App.css';
import React, { Component } from 'react';
import GoogleMap from "./heat_map";
import {SalesStockSwitch} from "./models";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            chartType:SalesStockSwitch.Sales.toString()
        };
    }

    handleChartChange(e){
        e.preventDefault();
        e.stopPropagation();

        this.setState({chartType:e.target.value})
    }

    render() {
        return<div className="App">
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <span className="navbar-brand">Controlled Dispensing Dashboard</span>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <select onChange={this.handleChartChange.bind(this)} className={"form-select"}>
                                <option value={SalesStockSwitch.Sales}>7 Days Dispensing Chart</option>
                                <option value={SalesStockSwitch.Stocks}>Stock Remaining Chart</option>
                            </select>
                            &nbsp;
                            <div className="btn-group">
                                <button type="button" className="btn btn-primary dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Filter By Stock
                                </button>
                                <ul className="dropdown-menu">
                                    <li><p className="dropdown-item">Low</p></li>
                                    <li><p className="dropdown-item">Medium</p></li>
                                    <li><p className="dropdown-item">High</p></li>
                                </ul>
                            </div>
                            &nbsp;
                            <div className="btn-group">
                                <button type="button" className="btn btn-warning dropdown-toggle"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                    Filter By Dispensing Number
                                </button>
                                <ul className="dropdown-menu">
                                    <li><p className="dropdown-item">Low</p></li>
                                    <li><p className="dropdown-item">Medium</p></li>
                                    <li><p className="dropdown-item">High</p></li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                </div>
            </nav>
            <GoogleMap chartType={this.state.chartType}/>
        </div>
    }
}

export default App;
